import React from "react"

const Button = ({ styles, label }) => (
  <button
    type="button"
    className={`py-2 px-4 font-poppins font-medium text-[18px] text-primary bg-black-gradient-3  rounded-[10px] outline-none ${styles} hover:text-secondary`}
  >
    {label}
  </button>
)

export default Button
