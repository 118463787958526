import React from "react"
import { Layout } from "../components/layout"
import Button from "../components/button"
import styles from "../styles/style"

import PineappleSandwich from "../images/dorney-sandwiches-v2.jpg"
import PalmerArmsFood from "../images/palmer-arms-food-v2.jpg"

const OurPartners = () => {
  return (
    <Layout>
      <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <section className="body-font ">
            <div className="container  mx-auto mb-6">
              <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mt-4">
                <span className="text-green-gradient">Partners</span>
              </h2>
              <p>
                Welcome to our partners and stockist section, please see a list
                of our new and exciting relationships, that we are building and
                looking forward to initiatives and events planned for the
                future. We at H&S Pepper would like to thanks all that has made
                this possible.
              </p>
            </div>
          </section>

          <section className="text-gray-700 body-font mt-6 mb-12 border-t border-gray-200">
            <div className="container mx-auto flex  md:flex-row flex-col items-center mt-8">
              <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-8 md:mb-0 items-center ">
                <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mb-2">
                  <span className="text-green-gradient">The Palmer Arms</span>
                </h2>
                <p className="mb-4 leading-relaxed">
                  The Palmer Arms public house dates from the 15th Century and
                  formed part of the Dorney Court estate.
                </p>
                <p className="mb-4 leading-relaxed">
                  The Old Brewery at Dorney Court used to brew the beer for The
                  Palmer Arms and the original copper for brewing the beer is
                  still there.
                </p>
                <p className="mb-4 leading-relaxed">
                  From Sunday roasts to light lunches, classic main meals and a
                  kids menu. They cater for most dietary requirements with
                  Gluten Free & Dairy free menu options available.
                </p>
                <p className="mb-4 leading-relaxed">
                  The Palmer Arms is renowned for its friendly service and
                  amazing food, which combined with H&S Pepper Co sauces is a
                  match made in heaven.
                </p>
                <div className="flex justify-center ">
                  <a
                    href="https://thepalmerarms.com/"
                    alt="The palmer arms pub in dorney website"
                  >
                    <Button
                      label="Website"
                      //styles={`mt-6`}
                    />
                  </a>
                </div>
              </div>
              <div className="lg:max-w-lg lg:w-full md:w-1/2 ">
                <img
                  className="object-cover object-center rounded"
                  alt="hero"
                  src={PalmerArmsFood}
                  //src="https://dummyimage.com/720x600/edf2f7/a5afbd"
                />
              </div>
            </div>
          </section>

          <section className="text-gray-700 body-font mt-6 mb-12 border-t border-gray-200">
            <div className="container mx-auto flex  md:flex-row flex-col items-center ">
              <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-8 md:mb-0 items-center ">
                <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mt-4 mb-2">
                  <span className="text-green-gradient">The Pineapple</span>
                </h2>
                <p className="mb-4 leading-relaxed">
                  The Pineapple is a Grade II listed 15th Century pub located in
                  Buckinghamshire’s most southerly village, close to the Thames
                  in Dorney,
                </p>
                <p className="mb-4 leading-relaxed">
                  They have their famous extensive Pineapple Sandwich menu,
                  something for everyone on your choice of breads and served
                  with chips, soup or salad.
                </p>
                <p className="mb-4 leading-relaxed">
                  New Stone based Pizza ovens, making the best pizza in town,
                  and on Sundays, home cook delicious Roast dinners served with
                  all the trimmings .
                </p>
                <p className="mb-4 leading-relaxed">
                  The Pineapple is renowned for its sandwiches, but provide so
                  much more in terms of their food and services which combined
                  with H&S Pepper Co sauces is simply delicious.
                </p>
                <div className="flex justify-center">
                  <a
                    href="https://www.thepineappledorney.co.uk/"
                    alt="The pineapple pub in dorney website"
                  >
                    <Button
                      label="website"
                      //styles={`mt-6`}
                    />
                  </a>
                </div>
              </div>
              <div className="lg:max-w-lg lg:w-full md:w-1/2 ">
                <img
                  className="object-cover object-center rounded"
                  alt="hero"
                  src={PineappleSandwich}
                  //src="https://dummyimage.com/720x600/edf2f7/a5afbd"
                />
              </div>
            </div>
          </section>

          <section className="text-gray-600 body-font border-t border-gray-200 mb-10">
            <div className="container mx-auto mt-8">
              <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mb-8">
                <span className="text-green-gradient">Stockists</span>
              </h2>
              <div className="flex items-center mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-green-gradient text-green-100 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="sm:w-16 sm:h-16 w-10 h-10"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                  <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                    Pempamsie Mitcham
                  </h2>
                  <p className="leading-relaxed text-base">
                    Natural Health Products, Organic Foods, Body & Hair
                    Products, Picture Framing, Books, African Art, Garments,
                    Jewellery and gifts
                  </p>
                  <span className="mt-3 text-green-500 inline-flex items-center">
                    <a href="/" alt="Pempamsie website link">
                      Pempamsie website
                    </a>
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="flex items-center mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                  <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                    Big Bens Fish & Chips
                  </h2>
                  <p className="leading-relaxed text-base">
                    This is the best chip shop in burnham and local area, every
                    thing is cooked fresh and served with a smile on their face
                    this is one for the locals but if you are ever in the area,
                    have a go
                  </p>
                  <span className="mt-3 text-green-500 inline-flex items-center">
                    <a
                      href="https://www.facebook.com/bigbensfishandchips"
                      alt="Pempamsie website link"
                    >
                      Big Bens Fish & Chips
                    </a>
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </span>
                </div>
                <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-green-gradient text-green-100  flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="sm:w-16 sm:h-16 w-10 h-10"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="6" cy="6" r="3"></circle>
                    <circle cx="6" cy="18" r="3"></circle>
                    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                  </svg>
                </div>
              </div>
              <div className="flex items-center mx-auto sm:flex-row flex-col">
                <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-green-gradient text-green-100 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="sm:w-16 sm:h-16 w-10 h-10"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                  <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                    J&S Rook Butchers
                  </h2>
                  <p className="leading-relaxed text-base">
                    J & S Rook Butchers is a family run business owned by Jerry
                    Rook who has over 35 years experience as a high quality
                    butcher and delicatessen. If you cook on kamado oven or
                    similar and use our sauces to marinade your meat then this
                    is the perfect starting place for that BBQ you are planning
                  </p>
                  <span className="mt-3 text-green-500 inline-flex items-center">
                    <a
                      href="http://www.jandsrook.co.uk/"
                      alt="J&S Rook Butchers website link"
                    >
                      J&S Rook Butchers Website
                    </a>
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </span>
                </div>
              </div>
              {/*  <button className="flex mx-auto mt-20 text-white bg-green-500 border-0 py-2 px-8 focus:outline-none hover:bg-green-600 rounded text-lg">
                Button
              </button> */}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default OurPartners
